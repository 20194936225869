import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.last-index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.array.some";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import _objectSpread from "D:/work/admin/sdk_manage_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import util from '@/libs/util';
export default {
  components: {
    'el-image-viewer': function elImageViewer() {
      return import('element-ui/packages/image/src/image-viewer');
    }
  },
  props: {
    value: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 1
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 20
    },
    ExtraTips: {
      type: String,
      default: ''
    },
    listType: {
      type: String,
      default: 'picture-card'
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: function _default() {
        return ["png", "jpg", "jpeg"];
      }
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    },
    // 是否显示提示
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      hideUpload: false,
      uploadImgUrl: process.env.VUE_APP_API + "appLoginTypeConfig/uploadImg",
      // 上传的图片服务器地址
      headers: {
        Authorization: "Bearer " + util.cookies.get('token')
      },
      fileList: []
    };
  },
  watch: {
    value: {
      handler: function handler(val) {
        var _this = this;

        if (val) {
          // 首先将值转为数组
          var list = Array.isArray(val) ? val : this.value.split(','); // 然后将数组转为对象数组

          this.fileList = list.map(function (item) {
            if (typeof item === "string") {
              if (item.indexOf(_this.$imgUrl) === -1) {
                item = {
                  name: _this.$utils.imgTrow(item),
                  url: _this.$utils.imgTrow(item)
                };
              } else {
                item = {
                  name: item,
                  url: item
                };
              }
            }

            return item;
          });
        } else {
          this.fileList = [];
          return [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 是否显示提示
    showTip: function showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    }
  },
  methods: {
    // 删除图片
    handleRemove: function handleRemove(file, fileList) {
      var findex = this.fileList.map(function (f) {
        return f.name;
      }).indexOf(file.name);

      if (findex > -1) {
        this.fileList.splice(findex, 1);
        this.$emit("input", this.listToString(this.fileList));
      }
    },
    showImgViewer: function showImgViewer() {
      this.dialogVisible = true;

      var m = function m(e) {
        e.preventDefault();
      };

      document.body.style.overflow = 'hidden';
      document.addEventListener("touchmove", m, false); // 禁止页面滑动
    },
    closeImgViewer: function closeImgViewer() {
      this.dialogVisible = false;

      var m = function m(e) {
        e.preventDefault();
      };

      document.body.style.overflow = 'auto';
      document.removeEventListener("touchmove", m, true);
    },
    // 上传成功回调
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      if (res.status == 200) {// this.fileList.push({ name: res.fileName || '', url: res.data });
      } else {
        this.$message.error("\u4E0A\u4F20\u6587\u4EF6\u5931\u8D25");
      }
    },
    handleChange: function handleChange(file, fileList) {
      var list = [];
      fileList.map(function (i) {
        list.push(_objectSpread(_objectSpread({}, i), {}, {
          status: !i.response ? 'uploading' : i.response.status == 200 ? 'success' : 'error'
        }));
      });
      this.fileList = list;
      this.$emit("input", this.listToString(this.fileList));
    },
    // 上传前loading加载
    handleBeforeUpload: function handleBeforeUpload(file) {
      var isImg = false;

      if (this.fileType.length) {
        var fileExtension = "";

        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }

        isImg = this.fileType.some(function (type) {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf("image") > -1;
      }

      if (!isImg) {
        this.$message.error("\u6587\u4EF6\u683C\u5F0F\u4E0D\u6B63\u786E, \u8BF7\u4E0A\u4F20".concat(this.fileType.join("/"), "\u56FE\u7247\u683C\u5F0F\u6587\u4EF6!"));
        return false;
      }

      if (this.fileSize) {
        var isLt = file.size / 1024 / 1024 < this.fileSize;

        if (!isLt) {
          this.$message.error("\u4E0A\u4F20\u5934\u50CF\u56FE\u7247\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7 ".concat(this.fileSize, " MB!"));
          return false;
        }
      } // this.loading = this.$loading({
      //   lock: true,
      //   text: "上传中",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });

    },
    // 文件个数超出
    handleExceed: function handleExceed() {
      this.$message.error("\u4E0A\u4F20\u6587\u4EF6\u6570\u91CF\u4E0D\u80FD\u8D85\u8FC7 ".concat(this.limit, " \u4E2A!"));
    },
    // 上传失败
    handleUploadError: function handleUploadError(res, file, fileList) {
      console.log(file, this.fileList);
      this.$message({
        type: "error",
        message: "上传失败"
      });
    },
    // 预览
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.showImgViewer();
    },
    listToString: function listToString(list) {
      // for (let i in list) {
      //  list[i].url = this.$utils.imgTrow(list[i].url) ;
      // }
      return list;
    }
  }
};